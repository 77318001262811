/**
 * custom wrapper for prismic rich text, since we need to split them into spans....
 * @param {*} props
 */
function TitleRichText(props) {
  const { richText } = props;

  // Not a richText...
  // DISABLED - not consistent on client-side vs. server-side
  /* if (richText.type.name !== 'RichText') {
    return richText;
  } */

  if (!richText.props || !richText.props.render || richText.props.render.length === 0) {
    return richText;
  }

  const { text, spans } = richText.props.render[0];

  let __html = text;

  // console.log('__html', __html);

  // parse all the spans
  for (const span of spans) {
    const { start, end, type } = span;
    // console.log('span', span);
    const subText = __html.substring(start, end);
    __html = __html.replace(subText, `<${type}>${subText}</${type}>`);
  }

  // remove double spaces, in case we made any by mistake
  __html = __html.replace(/ +(?= )/g, "");

  // split words by a space, and add them all wrapped in a span
  __html = __html
    .split(" ")
    .map((s) => `<span>${s}</span>`)
    .join(" ");

  // console.log('html =>', __html);

  return <p dangerouslySetInnerHTML={{ __html }} />;
}

export default TitleRichText;
