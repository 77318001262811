import {
  ActionField,
  Checkout,
  Events,
  Product,
  Purchase,
} from "~/lib/ga/enhancedEcommerce";
import { useDataLayer } from "./useDataLayer";

export function useEnhancedEcommerce(dataLayerName = "dataLayer") {
  const dataLayerCheckout = useDataLayer(dataLayerName);
  const dataLayerCheckoutOption = useDataLayer(dataLayerName);
  const dataLayerPurchase = useDataLayer(dataLayerName);
  const dataLayerViewProduct = useDataLayer(dataLayerName);

  function checkout(step: number, option: string = null, products: [Product] = null) {
    const actionField: ActionField = {
      step,
    };
    if (option) {
      actionField.option = option;
    }
    const checkout: Checkout = {
      actionField,
    };
    if (products) {
      checkout.products = products;
    }
    dataLayerCheckout.push({
      event: Events.Checkout,
      ecommerce: { checkout },
    });
  }

  function checkoutOption(step: number, option: string) {
    dataLayerCheckoutOption.push({
      event: Events.CheckoutOption,
      ecommerce: {
        checkout_option: {
          actionField: {
            step,
            option,
          },
        },
      },
    });
  }

  function purchase(
    id: string,
    currencyCode: string,
    revenue: string,
    shipping: string,
    tax: string,
    products: [Product],
    coupon: string = null
  ) {
    const actionField: ActionField = { id, revenue, shipping, tax };
    if (coupon) {
      actionField.coupon = coupon;
    }
    const purchase: Purchase = { actionField, products };
    dataLayerPurchase.push({
      event: Events.Purchase,
      ecommerce: { currencyCode, purchase },
    });
  }

  function viewProduct(product: Product) {
    dataLayerViewProduct.push({
      event: "view_item",
      ecommerce: {
        detail: {
          items: [
            {
              item_id: product.key,
              item_name: product.name,
            },
          ],
        },
      },
    });
  }

  return {
    checkout,
    checkoutOption,
    purchase,
    viewProduct,
  };
}
