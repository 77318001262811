import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function useFreeShipping() {
  // @ts-ignore
  const countryData = useSelector((s) => s.countryData);
  // @ts-ignore
  const shippingMethods = useSelector((s) => s.shippingMethods);

  const [freeShipping, setFreeShipping] = useState({
    centAmount: Infinity,
    currencyCode: "",
    fractionDigits: 2,
  });

  // console.log('freeShipping', freeShipping);

  function freeShippingExtraction() {
    if (
      !countryData.shipping.enabled ||
      !shippingMethods ||
      !shippingMethods.keys ||
      shippingMethods.keys.length === 0
    ) {
      return false;
    }
    const entries = Object.values<any>(shippingMethods.byKey)
      .map((shippingMethod) => {
        const zoneRate = shippingMethod.zoneRates.find(
          (zoneRate) => zoneRate.zone.name === countryData.zone
        );
        const shippingRate = zoneRate.shippingRates.find(
          (shippingRate) => shippingRate.price.currencyCode === countryData.currency
        );
        return shippingRate.freeAbove;
      })
      .filter((freeAbove) => freeAbove !== null);
    if (entries.length === 0) {
      return false;
    }
    const lowestThreshold = entries.sort((a, b) => a.centAmount - b.centAmount)[0];
    return lowestThreshold;
  }

  useEffect(() => {
    setFreeShipping(freeShippingExtraction());
  }, [shippingMethods.keys]);

  return freeShipping;

  /* if (shippingMethods.length === 0) {
    return null;
  }
  // console.log(countryData);
  // console.log(shippingMethods);
  const shippingMethod =
    shippingMethods.byKey[shippingMethods.selected || shippingMethods.keys[0]];
  // console.log(shippingMethod);
  // console.log(shippingMethod.zoneRates);
  const zoneRate = shippingMethod.zoneRates.find(zR =>
    zR.zone.locations.find(l => l.country === countryData.code)
  );
  if (!zoneRate) {
    return null;
  }
  const shippingRate = zoneRate.shippingRates.find(sR => sR.isMatching);

  return shippingRate; */
}

export default useFreeShipping;
