// USE PRICELINE INSTEAD!

// TODO: ADD TEST

// TODO: Use CTP model: Money?
interface PriceProperties {
  currencyCode: string;
  centAmount: number;
  fractionDigits: number;
  showFractions?: boolean;
  currencyStyle?: {};
  showPositiveSign?: boolean;
  discounted?: {
    currencyCode: string;
    centAmount: number;
    fractionDigits: number;
  };
  quantity: number;
}

function Price(props: PriceProperties) {
  // TODO: fractionDigits and discounted.fractionDigits can sometimes be undefined

  const { quantity } = props;
  const amount =
    ((props.centAmount + 0) / 10 ** (props.fractionDigits || 2)) * quantity;
  const showPlus = props.showPositiveSign && amount > 0;
  const amountFixed = amount.toFixed(
    amount % 1 > 0 || props.showFractions ? props.fractionDigits || 2 : 0
  );
  // DISABLED: Specific AIAIAI styling (lower-case + capitalize)
  const { currencyCode } = props; // .toLowerCase();
  const currencyStyle = props.currencyStyle || { fontSize: "smaller" };
  const { discounted } = props;
  const amountDiscounted =
    (discounted
      ? (discounted.centAmount + 0) / 10 ** (discounted.fractionDigits || 2)
      : 0) * quantity;
  const amountFixedDiscounted = discounted
    ? amountDiscounted.toFixed(
        amountDiscounted % 1 > 0 || props.showFractions
          ? discounted.fractionDigits || 2
          : 0
      )
    : null;
  return (
    <>
      <span style={{ ...currencyStyle /* , textTransform: 'capitalize' */ }}>
        {currencyCode}
      </span>{" "}
      {discounted ? (
        <>
          <span style={{ textDecoration: "line-through" }}>{amountFixed}</span>{" "}
          <span style={{ color: "tomato" }}>{amountFixedDiscounted}</span>
        </>
      ) : (
        <span>
          {showPlus && "+"}
          {amountFixed}
        </span>
      )}
    </>
  );
}

Price.defaultProps = {
  quantity: 1,
};

export default Price;
