/*
window.dataLayer.push({
  event: 'eec.checkout',
  ecommerce: {
    checkout: {
      actionField: {
        step: 1
      },
      products: [{
        id: 'product_id',
        name: 'MY PRODUCT',
        category: 'guides/google-tag-manager/enhanced-ecommerce',
        variant: 'Text',
        brand: 'SIMO AHAVA',
        quantity: 2,
        dimension3: 'Ecommerce',
        metric5: 12,
        metric6: 1002
      },{
        id: 'another_product_id',
        name: 'MY ADD-ON',
        quantity: 1
      }]
    }
  }
});
*/

// NOTE: Incomplete
export enum Events {
  Checkout = "eec.checkout",
  CheckoutOption = "eec.checkout_option",
  Purchase = "eec.purchase",
  Refund = "eec.refund",
  Impression = "eec.impressionView",
  Click = "eec.impressionClick",
}

export interface Event {
  event: Events;
  ecommerce: Ecommerce;
}

export interface Ecommerce {
  currencyCode?: string;
  checkout?: Checkout;
  checkout_option?: CheckoutOption;
  purchase?: Purchase;
  refund?: Refund;
  impressions?: [Product];
}

export interface ActionField {
  id?: string;
  step?: number;
  affiliation?: string;
  revenue?: string;
  tax?: string;
  shipping?: string;
  coupon?: string;
  list?: string;
  option?: string;
}

export interface Checkout {
  actionField: ActionField;
  products?: [Product];
}

export interface CheckoutOption {
  actionField: ActionField;
}

export interface Purchase {
  actionField: ActionField;
  products: [Product];
}

interface Dimension {
  dimension1?: string;
  dimension2?: string;
  dimension3?: string;
  dimension4?: string;
  dimension5?: string;
  dimension6?: string;
  dimension7?: string;
  dimension8?: string;
  dimension9?: string;
  dimension10?: string;
  dimension11?: string;
  dimension12?: string;
  dimension13?: string;
  dimension14?: string;
  dimension15?: string;
  dimension16?: string;
  dimension17?: string;
  dimension18?: string;
  dimension19?: string;
  dimension20?: string;
}

interface Metric {
  metric1?: string | number;
  metric2?: string | number;
  metric3?: string | number;
  metric4?: string | number;
  metric5?: string | number;
  metric6?: string | number;
  metric7?: string | number;
  metric8?: string | number;
  metric9?: string | number;
  metric10?: string | number;
  metric11?: string | number;
  metric12?: string | number;
  metric13?: string | number;
  metric14?: string | number;
  metric15?: string | number;
  metric16?: string | number;
  metric17?: string | number;
  metric18?: string | number;
  metric19?: string | number;
  metric20?: string | number;
}

export interface Product extends Dimension, Metric {
  id: string;
  // sku?: string; -- use above
  name: string;
  category?: string;
  variant?: string;
  brand?: string;
  quantity: number;
  price: string;
  position?: number;
}

export interface Refund {
  // TODO
}

export interface Click {
  actionField: ActionField;
  products: [Product];
}
