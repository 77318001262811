import styled from "styled-components";
import { smallerThan } from "~/styles/theme";

const DividerWrapper = styled.div`
  width: 100%;
  display: block;
  position: relative;
  padding-top: ${(p) => (p.noSpacingTop ? "0" : p.theme.sizes.vSpacing)}rem;
  padding-bottom: ${(p) => (p.noSpacingBottom ? "0" : p.theme.sizes.vSpacing)}rem;
  opacity: ${(p) => p.opacity || "1"};
  > div {
    height: 2px;
    ${(p) => smallerThan(p.theme.breakpoints.ipadPortrait)} {
      height: 1px;
    }
    background-color: ${(p) => p.color || p.theme.colors.dark};
  }
`;

function Divider(props) {
  const { color, opacity } = props;

  // we can se props.noSpacing if we want to have no spacing on  both1
  const noSpacingBottom = props.noSpacingBottom || props.noSpacing || false;
  const noSpacingTop = props.noSpacingTop || props.noSpacing || false;

  return (
    <DividerWrapper
      color={color}
      opacity={opacity}
      noSpacingBottom={noSpacingBottom}
      noSpacingTop={noSpacingTop}
    >
      <div />
    </DividerWrapper>
  );
}

// function Divider(props){
//   reuturn
// }

export default Divider;
