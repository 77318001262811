/**
 *
 * If not used with a prismic slice, you need to add all the needed elements, eg.:
 *  <Title line1={<p><span><strong>TEST</strong></span> <span>TEST</span></p>} />
 *
 */
import classnames from "classnames";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import Divider from "~/components/Divider";
import { smallerThan } from "~/styles/theme";
import TitleRichText from "./TitleRichText";

// som anim settings....
const animOffsetH = 10;
const animLineOffsetH = 10;

// TODO make proper animations with framer motion
export const TitleLine = styled.div<any>`
  line-height: 0.95;
  transform: translateY(0.25em);
  overflow: hidden; /* important for animations ! */

  .animate-in & {
    /* make transition?? */
    span {
      opacity: 0;
      transition: transform 1.2s, opacity 1.2s;
    }

    /* first word */
    span:nth-of-type(1) {
      position: relative;
      display: inline-block;
      transition-delay: ${(p) => 0.0 + p.lineIndex * 0.2}s;
      transform: translateX(
        ${(p) => animOffsetH * 1 + (p.lineIndex || 0) * animLineOffsetH}vh
      );
    }
    /* second word */
    span:nth-of-type(2) {
      position: relative;
      display: inline-block;
      transition-delay: ${(p) => 0.2 + p.lineIndex * 0.2}s;
      transform: translateX(
        ${(p) => animOffsetH * 2 + (p.lineIndex || 0) * animLineOffsetH}vh
      );
    }
    /* third word */
    span:nth-of-type(3) {
      position: relative;
      display: inline-block;
      transition-delay: ${(p) => 0.4 + p.lineIndex * 0.2}s;
      transform: translateX(
        ${(p) => animOffsetH * 3 + (p.lineIndex || 0) * animLineOffsetH}vh
      );
    }
    /* fourth word */
    span:nth-of-type(4) {
      position: relative;
      display: inline-block;
      transition-delay: ${(p) => 0.6 + p.lineIndex * 0.2}s;
      transform: translateX(
        ${(p) => animOffsetH * 4 + (p.lineIndex || 0) * animLineOffsetH}vh
      );
    }
  }

  .in-view & {
    span {
      /* color: red; */
      opacity: 1 !important;
      transform: translateX(0) !important;
    }
  }
`;

const TitleWrapper = styled.div<any>`
  position: relative;
  margin: 0;
  font-size: 16px; /* normalized */

  ${TitleLine} {
    > p {
      margin: 0;
      padding: 0;
      -webkit-text-stroke: 0.02em;

      font-size: ${(p) => p.size * 6.1}vw;
      color: ${(p) => p.color || p.theme.colors.dark};
      font-weight: ${(p) => p.fontWeight || "400"};
      font-family: "AIAIAI${(p) => p.fontStyle || ""}";

      ${(p) => smallerThan(p.theme.breakpoints.small)} {
        font-size: 10vw;
      }
      position: relative;
      b,
      strong {
        font-weight: inherit;
        color: ${(p) => p.highlightColor || p.theme.colors.primary};
        ${(p) =>
          p.highlightOutline &&
          `
        color: ${(p) => p.highlightColor || p.theme.colors.primary};
        fill-color: transparent;
        -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
        -webkit-text-stroke: 6px ${(p) => p.highlightColor || p.theme.colors.primary};
        `}
      }
    }
  }
`;

interface TitleProps {
  highlightColor?: string;
  highlightOutline?: boolean;
  color?: string;
  type?: string;
  line1: any;
  line2?: any;
  line3?: any;
  size?: number;
  animateIn?: boolean;
  fontWeight?: string;
  fontStyle?: string;
}

function Title(props: TitleProps) {
  const {
    highlightColor,
    highlightOutline,
    color,
    type = "div",
    line1 = null,
    line2 = null,
    line3 = null,
    size = 1,
    animateIn = false,
    fontWeight = "400",
    fontStyle = "",
  } = props;

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: "100px 0px",
  });

  return (
    // @ts-ignore (2743)
    // Related to https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30117
    <TitleWrapper
      // @ts-ignore
      // Related to https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30117
      as={type}
      ref={ref}
      size={size}
      highlightColor={highlightColor}
      highlightOutline={highlightOutline}
      color={color}
      className={classnames({
        "in-view": inView,
        "animate-in": animateIn,
      })}
      fontWeight={fontWeight}
      fontStyle={fontStyle}
    >
      <>
        {line1 && line2 && line3 && (
          <>
            <Divider color={color} opacity={0.26} />
            <TitleLine lineIndex={0}>
              <TitleRichText richText={line1} />
            </TitleLine>
            <Divider color={color} opacity={0.46} />
            <TitleLine lineIndex={1}>
              <TitleRichText richText={line2} />
            </TitleLine>
            <Divider color={color} opacity={0.66} />
            <TitleLine lineIndex={2}>
              <TitleRichText richText={line3} />
            </TitleLine>
            <Divider color={color} />
          </>
        )}

        {line1 && line2 && !line3 && (
          <>
            <Divider color={color} opacity={0.26} />
            <TitleLine lineIndex={0}>
              <TitleRichText richText={line1} />
            </TitleLine>
            <Divider color={color} opacity={0.56} />
            <TitleLine lineIndex={1}>
              <TitleRichText richText={line2} />
            </TitleLine>
            <Divider color={color} />
          </>
        )}

        {line1 && !line2 && !line3 && (
          <>
            <Divider color={color} opacity={0.56} />
            <TitleLine lineIndex={0}>
              <TitleRichText richText={line1} />
            </TitleLine>
            <Divider color={color} />
          </>
        )}
      </>
    </TitleWrapper>
  );
}

export default Title;
